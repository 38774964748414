import './App.css';
import React, { useState } from 'react';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable'; 
import { Column } from 'primereact/column';
import { useRef } from 'react';
import 'primeicons/primeicons.css';

function App() {
  const [products, setProducts] = useState([]);
  const inputRef = useRef(null);

  const handleAdd = () => {
    const inputValue = inputRef.current.value;

    if (inputValue){
      const newProduct = {name: inputValue};
      const newProducts = [...products, newProduct];  
      inputRef.current.value = '';
      console.log(newProducts);
      setProducts(newProducts);
    }
  }

  const removeProduct = (name) => { setProducts(products.filter(product => product.name !== name)); }

  return (
    <PrimeReactProvider>
        <div className='App'>
          <h1>Grocery List</h1>
          <div className='p-inputgroup'>
            <InputText placeholder='Enter a product name' ref={inputRef}/>
            <Button label='Add' onClick={handleAdd}/>
          </div>
          <br/>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <DataTable value={products}>
              <Column field="name" header="Product" />
              <Column body={(rowData) => <i className='pi pi-trash' onClick={() => removeProduct(rowData.name)} />} />
            </DataTable>
          </div>
        </div>
    </PrimeReactProvider>
  );
}

export default App;

